<template>
  <div>
    <!-- 新增經銷商 -->
    <dealer-list-add-modal
      ref="domainAddModal"
      @refetch-data="refetchData"
    />

    <!-- 經銷商編輯 -->
    <dealer-list-edit-modal
      v-if="selected"
      ref="domainEditModal"
      :domain-item="selected"
      @refetch-data="refetchData"
    />

    <!-- 搜尋欄 -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>篩選器</h5>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            lg="3"
            md="4"
            class="mb-md-0 mb-1"
          >
            <label>網域商</label>
            <v-select
              v-model="searchAgentId"
              :options="adminDomainState.domainAgentOptions"
              class="w-100"
              label="agent"
              :reduce="option => option.id"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>

    <!-- 經銷商列表 -->
    <b-card no-body>
      <div class="mx-2 mt-2 mb-50">
        <b-row>
          <b-col
            cols="12"
            md="8"
            sm="6"
          >
            <h4 class="mb-0">
              經銷商列表
            </h4>
          </b-col>

          <b-col
            cols="12"
            md="4"
            sm="6"
          >
            <div class="text-nowrap d-flex justify-content-end">
              <div
                v-b-tooltip.hover.focus.v-secondary
                title="新增"
                class="d-flex align-items-center actions-link-btn"
                @click="onSubmitUpdate(null)"
              >
                <b-img
                  src="/dashboard/admin/images/table/plus.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="refetchTable"
              >
                <b-img
                  src="/dashboard/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="列表顯示"
                class="d-flex align-items-center actions-link-btn ml-25"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <b-img
                      src="/dashboard/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </template>

                  <b-dropdown-form>
                    <b-form-group>
                      <label class="mb-50">
                        列表顯示
                      </label>

                      <b-form-checkbox
                        v-for="column in tableColumnsSearchable"
                        :key="column.key"
                        v-model="column.select"
                        name="table-column"
                        class="mb-1"
                      >
                        {{ column.label }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-dropdown-form>

                </b-dropdown>
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="創建日期"
                class="d-flex align-items-center actions-link-btn ml-25"
                :class="timeRange ? 'actions-link-btn-active' : null"
                @click="() => { $refs.TableTimeRange.getData(timeRange) }"
              >
                <b-img
                  src="/dashboard/admin/images/table/calendar.svg"
                  class="actions-link-btn-image"
                  rounded
                />

                <TableTimeRange
                  ref="TableTimeRange"
                  table-explorer-id="TableTimeRange"
                  @call-back-data="time => timeRange = time"
                />
              </div>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 顯示個數/搜尋框 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示個數 -->
          <b-col
            cols="12"
            md="6"
            sm="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
          >
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="table-perPage-select"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
            <!-- per-page-selector d-inline-block   -->
          </b-col>

          <!-- 搜尋框 -->
          <b-col
            cols="12"
            md="6"
            sm="8"
          >
            <!-- 搜尋框 -->
            <div class="w-100 mr-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  debounce="500"
                  class="d-inline-block"
                  placeholder="搜尋..."
                />
                <b-input-group-append
                  v-if="searchQuery"
                  is-text
                >
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer text-muted"
                    @click="searchQuery = null"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 列表 -->
      <b-table
        ref="refDataListTable"
        :items="getDomainDealerData"
        :fields="tableColumnsFilter"
        responsive
        striped
        hover
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        class="position-relative"
        details-td-class="p-0"
        :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        <!-- 忙碌中 -->
        <template #table-busy>
          <b-skeleton-table
            :rows="5"
            :columns="tableColumnsFilter.length"
            :table-props="{ bordered: true, striped: true }"
          />
        </template>

        <!-- 查無資料 -->
        <template #empty>
          <div class="text-center my-5 animate__animated animate__fadeIn">
            <b-img
              :src="$store.state.app.themeImages.notFoundImg"
              fluid
              width="480"
              alt="查無資料"
            />
          </div>
        </template>

        <!-- 欄位: 編號 -->
        <template #cell(id)="data">
          <div class="table-col">
            <span>{{ data.item.id }}</span>
          </div>
        </template>

        <!-- 欄位: 網域商 -->
        <template #cell(domain_name_agent_id)="data">
          <div class="table-col">
            {{ resolveTDomainAgentId(data.item.domain_name_agent_id) }}
          </div>
        </template>

        <!-- 欄位: 名稱 -->
        <template #cell(name)="data">
          <div class="d-flex align-items-center">
            <div class="w-100">
              <b-link
                class="font-weight-bold d-block text-nowrap show-text"
                @click="onSubmitUpdate(data.item)"
              >
                {{ data.item.name }}
              </b-link>
            </div>
          </div>
        </template>

        <!-- 欄位: 創建時間 -->
        <template #cell(created_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.created_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.created_at).format('HH:mm:ss')}`"
              >
                {{ updateOnline(data.item.created_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 更新時間 -->
        <template #cell(updated_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.updated_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.updated_at).format('HH:mm:ss')}`"
              >
                {{ updateOnline(data.item.updated_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 動作 -->
        <template #cell(actions)="data">
          <div class="d-flex mb-50">
            <div
              class="actions-link-btn mr-25"
              @click="onSubmitCopy(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="複製"
                src="/dashboard/admin/images/table/copy.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              class="actions-link-btn"
              @click="onSubmitUpdate(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="編輯"
                src="/dashboard/admin/images/table/edit.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </div>
        </template>
      </b-table>

      <!-- 顯示頁數/分頁 -->
      <div class="m-2">
        <b-row>
          <!-- 顯示頁數 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
          </b-col>

          <!-- 分頁 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNum"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
// API
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'

// UI
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BLink, BInputGroupAppend,
  BDropdownForm, BFormInput, BInputGroupPrepend, BImg, BCardHeader,
  BInputGroup, BFormGroup, BFormCheckbox, BCardBody, BSkeletonTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'

import router from '@/router'
import store from '@/store'

// Component
import { useDomainDealer, useDomainSetting } from '../useDomain'
import useStoreModule from '../useStoreModule'
import DealerListAddModal from './DealerListAddModal.vue'
import DealerListEditModal from './DealerListEditModal.vue'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BLink,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownForm,
    BCardHeader,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BCardBody,
    BSkeletonTable,
    BInputGroupAppend,

    vSelect,
    TableTimeRange,
    DealerListAddModal,
    DealerListEditModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      title: router.currentRoute.meta.pageTitle,
      selected: null,
    }
  },
  computed: {
    tableColumnsFilter() {
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      return this.tableColumns
        .filter(f => f.searchable)
    },
    adminDomainState() {
      return this.$store.state['admin-domain']
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (轉換)第三方支付名稱
    resolveTDomainAgentId(id) {
      const item = this.adminDomainState.domainAgentOptions.find(f => f.id === id)
      return item ? item.agent : id
    },

    // (觸發)編輯
    onSubmitCopy(item) {
      this.$refs.domainAddModal.getData(item)
    },

    // (觸發)新增/編輯
    onSubmitUpdate(item) {
      if (!item) {
        this.$refs.domainAddModal.getData()
        return
      }
      this.selected = item
      setTimeout(() => { this.$refs.domainEditModal.getData() }, 200)
    },
  },
  setup() {
    // 註冊模組
    const DOMAIN_ADMIN_STORE_MODULE_NAME = 'admin-domain'

    if (!store.hasModule(DOMAIN_ADMIN_STORE_MODULE_NAME)) store.registerModule(DOMAIN_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(DOMAIN_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(DOMAIN_ADMIN_STORE_MODULE_NAME)
    })

    const {
      ui,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useDomainSetting()

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      searchAgentId,
      timeRange,
      blankDomainDealerData,
      domainData,
      refetchData,
      refetchTable,

      getDomainDealerData,
      setDomainDealerDelete,
      // getDomainAgentData,
      useAlertToast,
      useHttpCodeAlert,
    } = useDomainDealer()

    // getDomainAgentData({ key: 'domainNameAgent' }, () => {})

    return {
      ui,
      refonlineTime,
      onlineTime,
      updateOnline,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      searchAgentId,
      timeRange,
      blankDomainDealerData,
      domainData,
      refetchData,
      refetchTable,

      getDomainDealerData,
      setDomainDealerDelete,
      // getDomainAgentData,
      useAlertToast,
      useHttpCodeAlert,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.table-col {
  min-width: 40px;
}

.table-title {
  min-width: 100px;
}

.table-image {
  min-width: 90px;
  max-width: 100%;
  max-height: 30px;
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}
.item-description-email {
  // background-color: yellow;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px !important;
  font-size: 12px;
}
.server-name:hover {
  color: red !important;
  text-decoration: underline
}

.border-search {
  display: flex;
  flex-wrap: wrap;
}

.selection-group {
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
}

.selection-group:hover {
  .selection-btn-icon-show {
    opacity: 1;
  }
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
